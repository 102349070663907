export class UserLocation {
    constructor(latitude, longitude) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    copyWith({ latitude, longitude }) {
        return new UserLocation(
            latitude !== undefined ? latitude : this.latitude,
            longitude !== undefined ? longitude : this.longitude
        );
    }

    toMap() {
        return {
            latitude: this.latitude,
            longitude: this.longitude
        };
    }

    static fromMap(map) {
        return new UserLocation(
            map.latitude ?? 0.0,
            map.longitude ?? 0.0
        );
    }

    toJson() {
        return JSON.stringify(this.toMap());
    }

    static fromJson(json) {
        return UserLocation.fromMap(JSON.parse(json));
    }

    toString() {
        return `UserLocation(latitude: ${this.latitude}, longitude: ${this.longitude})`;
    }

    equals(other) {
        return other instanceof UserLocation &&
            this.latitude === other.latitude &&
            this.longitude === other.longitude;
    }

    hashCode() {
        return (this.latitude.toString() + this.longitude.toString()).hashCode();
    }
}

export class UserAccountSettingsModel {
    constructor({
        location = null,
        distanceInKm = null,
        interestedIn = null,
        minimumAge = 0,
        maximumAge = 0,
        showAge = null,
        showLocation = null,
        showOnlineStatus = null
    }) {
        this.location = location;
        this.distanceInKm = distanceInKm;
        this.interestedIn = interestedIn;
        this.minimumAge = minimumAge;
        this.maximumAge = maximumAge;
        this.showAge = showAge;
        this.showLocation = showLocation;
        this.showOnlineStatus = showOnlineStatus;
    }

    copyWith({
        location,
        distanceInKm,
        interestedIn,
        minimumAge,
        maximumAge,
        showAge,
        showLocation,
        showOnlineStatus
    }) {
        return new UserAccountSettingsModel({
            location: location !== undefined ? location : this.location,
            distanceInKm: distanceInKm !== undefined ? distanceInKm : this.distanceInKm,
            interestedIn: interestedIn !== undefined ? interestedIn : this.interestedIn,
            minimumAge: minimumAge !== undefined ? minimumAge : this.minimumAge,
            maximumAge: maximumAge !== undefined ? maximumAge : this.maximumAge,
            showAge: showAge !== undefined ? showAge : this.showAge,
            showLocation: showLocation !== undefined ? showLocation : this.showLocation,
            showOnlineStatus: showOnlineStatus !== undefined ? showOnlineStatus : this.showOnlineStatus
        });
    }

    toMap() {
        const result = {};

        if (this.location) {
            result.location = this.location.toMap();
        }
        if (this.distanceInKm !== null) {
            result.distanceInKm = this.distanceInKm;
        }
        if (this.interestedIn !== null) {
            result.interestedIn = this.interestedIn;
        }
        result.minimumAge = this.minimumAge;
        result.maximumAge = this.maximumAge;
        if (this.showAge !== null) {
            result.showAge = this.showAge;
        }
        if (this.showLocation !== null) {
            result.showLocation = this.showLocation;
        }
        if (this.showOnlineStatus !== null) {
            result.showOnlineStatus = this.showOnlineStatus;
        }

        return result;
    }

    static fromMap(map) {
        return new UserAccountSettingsModel({
            location: UserLocation.fromMap(map.location),
            distanceInKm: map.distanceInKm,
            interestedIn: map.interestedIn,
            minimumAge: map.minimumAge ?? 0,
            maximumAge: map.maximumAge ?? 0,
            showAge: map.showAge,
            showLocation: map.showLocation,
            showOnlineStatus: map.showOnlineStatus
        });
    }

    toJson() {
        return JSON.stringify(this.toMap());
    }

    static fromJson(json) {
        return UserAccountSettingsModel.fromMap(JSON.parse(json));
    }

    toString() {
        return `UserAccountSettingsModel(location: ${this.location}, distanceInKm: ${this.distanceInKm}, interestedIn: ${this.interestedIn}, minimumAge: ${this.minimumAge}, maximumAge: ${this.maximumAge}, showAge: ${this.showAge}, showLocation: ${this.showLocation}, showOnlineStatus: ${this.showOnlineStatus})`;
    }

    equals(other) {
        return other instanceof UserAccountSettingsModel &&
            this.location.equals(other.location) &&
            this.distanceInKm === other.distanceInKm &&
            this.interestedIn === other.interestedIn &&
            this.minimumAge === other.minimumAge &&
            this.maximumAge === other.maximumAge &&
            this.showAge === other.showAge &&
            this.showLocation === other.showLocation &&
            this.showOnlineStatus === other.showOnlineStatus;
    }

    hashCode() {
        return (
            this.location.hashCode() +
            this.distanceInKm.toString() +
            this.interestedIn +
            this.minimumAge.toString() +
            this.maximumAge.toString() +
            (this.showAge ? "1" : "0") +
            (this.showLocation ? "1" : "0") +
            (this.showOnlineStatus ? "1" : "0")
        ).hashCode();
    }
}

// Add a simple hashCode implementation for strings
String.prototype.hashCode = function () {
    let hash = 0, i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
