import { firestore } from "../main_firebase";
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { UserProfileModel } from "../../lib/models/user";

export async function getUser(uid) {
    try {
        if (!uid) return null;

        const diffTime = Math.abs(Date.now() - localStorage.getItem("last_user_update"));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        const localUser = JSON.parse(localStorage.getItem("user"))

        if (diffDays === 0 && localUser) {
            return localUser
        }

        const result = await getDoc(doc(firestore, `userProfile/${uid}`))

        const user = UserProfileModel.fromMap(result.data())
        saveUser(user)
        return user
    } catch (error) {
        console.error(error.code, error.message)
        alert(error.message)
        return null
    }
}

export async function getAnyUser(uid) {
    try {
        if (!uid) return null;

        const result = await getDoc(doc(firestore, `userProfile/${uid}`))

        const user = UserProfileModel.fromMap(result.data())
        return user
    } catch (error) {
        console.error(error.code, error.message)
        alert(error.message)
        return null
    }
}

export async function getUsersByIds(ids) {
    try {

        const result = await getDocs(query(collection(firestore, "userProfile"), where("userId", "in", ids)))

        const users = result.docs.map((doc) => UserProfileModel.fromMap(doc.data()))

        return users
    } catch (error) {
        console.error(error.code, error.message)
        alert(error.message)
        return null
    }
}

function saveUser(user) {
    localStorage.setItem("user", JSON.stringify(user))
}