export function getTimeDifference(fromMillis) {
    const s = {
        recently: 'birkaç saniye',
        minutes: 'dakika',
        hours: 'saat',
        days: 'gün'
    };

    const firstDate = fromMillis;
    const secondDate = Date.now();

    const difference = Math.abs(secondDate - firstDate);
    const differenceInMinutes = Math.floor(difference / 60000);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInMinutes < 1) {
        return s.recently;
    } else if (differenceInMinutes < 60) {
        return `${differenceInMinutes} ${s.minutes}`;
    } else if (differenceInHours < 24) {
        return `${differenceInHours} ${s.hours}`;
    } else {
        return `${differenceInDays} ${s.days}`;
    }
}

export function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}

export function calculateAge(birthdayMillis) {
    // Get the current date
    const now = new Date(Date.now());

    // Convert birthday from milliseconds to a Date object
    const birthday = new Date(birthdayMillis);

    if (!birthday) {
        return null
    }

    // Calculate the difference in years
    let age = now.getFullYear() - birthday.getFullYear();

    // Adjust if the birthday hasn't occurred yet this year
    const monthDifference = now.getMonth() - birthday.getMonth();
    const dayDifference = now.getDate() - birthday.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}

export function generateRandomId(len) {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < len) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}