import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../main_firebase";
import { getAnyUser } from "../auth/user_data";

export async function getChats(user, setChats, users) {
    if (!user) return;

    let usersData = users;

    // Set up real-time Firestore listener
    const unsubscribe = onSnapshot(
        query(
            collection(firestore, "matches"),
            where("userIds", "array-contains", user.userId),
            orderBy("last_time", "desc")
        ),
        async (snapshot) => {
            if (snapshot.docs.length > 0) {
                const chats = await Promise.all(
                    snapshot.docs.map(async (chatDoc) => {
                        const chatData = chatDoc.data();
                        const matchedUserId = chatData.userIds.find(id => id !== user.userId);

                        // Check if the matched user is already in the cached users data
                        let matchedUser = usersData.find(user => user.userId === matchedUserId);
                        if (!matchedUser) {
                            matchedUser = await getAnyUser(matchedUserId);
                            usersData.push(matchedUser); // Update usersData with the new user
                        }

                        return { ...chatData, matchedUser };
                    })
                );

                // Update the chats state after all data is processed
                setChats(chats);
            } else {
                setChats([]); // No chats found
            }
        }
    );

    return unsubscribe; // Return the unsubscribe function for cleanup if needed
}

export async function getChat(setChat, matchId) {
    const unsubscribe = onSnapshot(
        query(
            collection(firestore, `matches/${matchId}/chat`),
            orderBy("createdAt", "asc")
        ),
        async (snapshot) => {
            if (snapshot.docs.length > 0) {
                const chat = snapshot.docs.map((val) => val.data());
                setChat(chat);
            }
        }
    );

    return unsubscribe; // Return the unsubscribe function for cleanup if needed
}
