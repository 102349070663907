import { getDocs, query, collection, where, limit } from "firebase/firestore";
import { firestore } from "../main_firebase";

// Haversine formula to calculate distance between two points (lat1, lon1) and (lat2, lon2)
export function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
}

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

// 2. Firestore query with the modular SDK
export const getUsersNearby = async (userData, currentUserLat, currentUserLon) => {

    const radiusInKm = 10; // Define the radius within which you want to find users

    // 1. Calculate bounding box for Firestore query
    const latRange = radiusInKm / 111.32; // 1 degree latitude ~= 111.32 km
    const lonRange = radiusInKm / (111.32 * Math.cos(degreesToRadians(currentUserLat)));

    // Calculate min/max lat/lon for Firestore query
    const minLat = currentUserLat - latRange;
    const maxLat = currentUserLat + latRange;
    const minLon = currentUserLon - lonRange;
    const maxLon = currentUserLon + lonRange;

    const userQuery = query(
        collection(firestore, 'userProfile'),
        where('userAccountSettingsModel.location.latitude', '>=', minLat),
        where('userAccountSettingsModel.location.latitude', '<=', maxLat),
        where('userAccountSettingsModel.location.longitude', '>=', minLon),
        where('userAccountSettingsModel.location.longitude', '<=', maxLon),
        where("gender", "==", (userData.gender === "male" || userData.gender === "erkek") ? "female" : "male"),
        limit(40)
    );

    try {
        const querySnapshot = await getDocs(userQuery);

        const users = [];

        querySnapshot.forEach((doc) => {
            const userData = doc.data();
            const userLat = userData.userAccountSettingsModel.location.latitude;
            const userLon = userData.userAccountSettingsModel.location.longitude;

            // 3. Calculate the actual distance between current user and each returned user
            const distance = calculateDistance(currentUserLat, currentUserLon, userLat, userLon);
            if (distance <= radiusInKm) {
                users.push(userData)
            }
        });

        return users
    } catch (error) {
        console.log("Error getting documents: ", error);
    }
};

export async function getMatchId(currentUserId, userId) {
    const snapshot = await getDocs(query(collection(firestore, "matches"), where("userIds", "array-contains", currentUserId)));
    if (snapshot.docs.length === 0) {
        return null
    } else {
        if (snapshot.docs.filter((val) => val.data().userIds.includes(userId)).length > 0) {
            return snapshot.docs.filter((val) => val.data().userIds.includes(userId))[0].data().id
        }
        return null
    }
}