import { Fragment, useEffect, useState } from "react";
import "../assets/css/extra/hometwo.css";
import Header from "../component/layout/header";
import '../assets/css/extra/chats.css';
import { getUser } from "../scripts/auth/user_data";
import { getChats } from "../scripts/chats/chats";
import { getTimeDifference } from "../scripts/utils/utils";
import Footer from "../component/layout/footer";

const Chats = () => {

    const [user, setUser] = useState(null)
    const [chats, setChats] = useState(null)
    const [notFound, setNotFound] = useState(false)

    const getUserData = async () => {
        const userData = await getUser(localStorage.getItem("user-id"))
        setUser(userData)
        if (userData && userData.userId) {
            const result = await getChats(userData, (val) => setChats(val), [])
            if (!result) {
                setNotFound(true)
            }
        }
    }

    useEffect(() => {
        getUserData()
    }, [])

    return (
        <Fragment>
            <Header />
            <p className="title_hometwo">Sohbetler</p>
            {
                (notFound) ? <div className="not-found">
                    Sohbetler Bulunamadı
                </div> : (user && chats) ? <div className="chats-lay">
                    <div className="site chats-content">
                        <div className="info-card mb-4">
                            <div className="info-card-content">
                                <ul className="info-list">
                                    {chats.map((val, i) => (
                                        <li key={i}>
                                            <a className="chat-item" href={`../chats/${val.matchedUser.userId}/${val.id}`}>
                                                <div className="id-left">
                                                    <img
                                                        className="user-thumb-0"
                                                        src={`${val.matchedUser.profilePicture || "https://www.pngrepo.com/png/5319/512/user.png"}`}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://www.pngrepo.com/png/5319/512/user.png";
                                                        }
                                                        } alt={`user thumb`} />
                                                </div>
                                                <div className="id-right">
                                                    <h6>{val.matchedUser.fullName}</h6>
                                                    <div className="id-name">{val.last_message}</div>
                                                </div>
                                                <div className="spacer-0"></div>
                                                <div className="id-date">
                                                    <p className="id-date-text">{getTimeDifference(val.last_time.toDate().getTime())} önce</p>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> : <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400 }}>
                    <span className="loader" style={{ width: 48, height: 48 }}></span>
                </div>
            }

            <Footer />

        </Fragment>
    );
}

export default Chats;