import { Fragment, useEffect, useState } from "react";
import HeaderOne from "../component/layout/header";
import { getCities, submitPost } from "../scripts/home/home";
import { getUser, getUsersByIds } from "../scripts/auth/user_data";
import { getTimeDifference } from "../scripts/utils/utils";
import { addDoc, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc, startAfter, Timestamp, where } from "firebase/firestore";
import { firestore } from "../scripts/main_firebase";
import Footer from "../component/layout/footer";
import '../assets/css/extra/home.css';
import { useNavigate } from "react-router-dom";

const HomePage = () => {

    const [user, setUser] = useState(null)
    const [males, setMales] = useState([])
    const [females, setFemales] = useState([])
    const [cities, setCities] = useState(null)
    const [city, setCity] = useState(null)
    const [comment, setComment] = useState("")
    const [caption, setCaption] = useState("")
    const [selectedPhotos, setSelectedPhotos] = useState([])
    const [selectedPhotoLinks, setSelectedPhotoLinks] = useState([])
    const [loading, setLoading] = useState(false)

    const [lastMaleDoc, setLastMaleDoc] = useState(null);
    const [lastFemaleDoc, setLastFemaleDoc] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);

    const navigate = useNavigate();

    function uniquePosts(posts) {
        const newPosts = []
        for (let post of posts) {
            if (newPosts.map((val) => val.id).includes(post.id)) {
                continue;
            } else {
                newPosts.push(post)
            }
        }
        return newPosts
    }

    // Function to load more male posts
    async function loadMoreMalePosts(cityData) {
        if (loadingMore) return; // Prevent multiple triggers
        setLoadingMore(true);

        let maleConditions = [
            where("status", "==", "accepted"),
            where("gender", "==", "male"),
            orderBy("createdAt", "desc"),
            limit(10)
        ];

        if (cityData) {
            maleConditions.push(where("city", "==", cityData));
        } else if (city) {
            maleConditions.push(where("city", "==", city));
        }

        if (lastMaleDoc) {
            maleConditions.push(startAfter(lastMaleDoc));
        }

        const maleQuery = query(collection(firestore, "feeds"), ...maleConditions);
        const malesSnapshot = await getDocs(maleQuery);

        if (!malesSnapshot.empty) {
            const maleIds = malesSnapshot.docs.map((doc) => doc.data()["userId"]);
            const users = await getUsersByIds(maleIds);
            const getUserData = (userId) => users.find(user => user.userId === userId) || {};
            const newMalePosts = [];

            for (let doc of malesSnapshot.docs) {
                const likes = await getDocs(collection(firestore, `feeds/${doc.id}/likes`));
                const comments = await getDocs(collection(firestore, `feeds/${doc.id}/comments`));

                newMalePosts.push({
                    ...doc.data(),
                    user: getUserData(doc.data()["userId"]),
                    likes: likes.docs,
                    comments: comments.docs.map((v) => v.data()),
                    commentsEnabled: false
                });
            }

            setMales((prevMales) => uniquePosts([...prevMales, ...newMalePosts]));
            setLastMaleDoc(malesSnapshot.docs[malesSnapshot.docs.length - 1]);
        }

        setLoadingMore(false);
    }

    // Function to load more female posts
    async function loadMoreFemalePosts(cityData) {
        if (loadingMore) return; // Prevent multiple triggers
        setLoadingMore(true);

        let femaleConditions = [
            where("status", "==", "accepted"),
            where("gender", "==", "female"),
            orderBy("createdAt", "desc"),
            limit(10)
        ];

        if (cityData) {
            femaleConditions.push(where("city", "==", cityData));
        } else if (city) {
            femaleConditions.push(where("city", "==", city));
        }

        if (lastFemaleDoc) {
            femaleConditions.push(startAfter(lastFemaleDoc));
        }

        const femaleQuery = query(collection(firestore, "feeds"), ...femaleConditions);
        const femalesSnapshot = await getDocs(femaleQuery);

        if (!femalesSnapshot.empty) {
            const femaleIds = femalesSnapshot.docs.map((doc) => doc.data()["userId"]);
            const users = await getUsersByIds(femaleIds);
            const getUserData = (userId) => users.find(user => user.userId === userId) || {};
            const newFemalePosts = [];

            for (let doc of femalesSnapshot.docs) {
                const likes = await getDocs(collection(firestore, `feeds/${doc.id}/likes`));
                const comments = await getDocs(collection(firestore, `feeds/${doc.id}/comments`));

                newFemalePosts.push({
                    ...doc.data(),
                    user: getUserData(doc.data()["userId"]),
                    likes: likes.docs,
                    comments: comments.docs.map((v) => v.data()),
                    commentsEnabled: false
                });
            }

            setFemales((prevFemales) => uniquePosts([...prevFemales, ...newFemalePosts]));
            setLastFemaleDoc(femalesSnapshot.docs[femalesSnapshot.docs.length - 1]);
        }

        setLoadingMore(false);
    }

    // Scroll event handler
    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && !loadingMore) {
            // Trigger loading more posts
            loadMoreMalePosts();
            loadMoreFemalePosts();
        }
    }

    // Add event listener for scrolling
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [loadingMore]);

    useEffect(() => {
        // Load the first batch of male and female posts on component mount
        loadMoreMalePosts();
        loadMoreFemalePosts();
    }, []);

    async function getUserData(uid) {
        const userData = await getUser(uid)
        setUser(userData)
        if (userData && userData.gender === "female") {
            selectGender(1)
        }
        const citiesData = await getCities()
        setCities(citiesData)
    }

    function selectGender(num) {

        if (!document.getElementById("female_feeds") || !document.getElementById("male_feeds")) {
            setTimeout(() => { selectGender(num) }, 1000)
            return
        }

        const malesTab = document.getElementById("males-tab")
        const femalesTab = document.getElementById("females-tab")

        if (num === 0) {
            document.getElementById("female_feeds").style.display = ""
            document.getElementById("male_feeds").style.display = "none"
            femalesTab.classList.add("active")
            malesTab.classList.remove("active")
        } else {
            document.getElementById("male_feeds").style.display = ""
            document.getElementById("female_feeds").style.display = "none"
            malesTab.classList.add("active")
            femalesTab.classList.remove("active")
        }
    }

    function PostImage({ link }) {
        return <img src={link} onClick={() => {
            window.location.href = link
        }} style={{ maxHeight: 250, objectFit: "cover", borderRadius: 10 }} alt="dating thumb" />;
    }

    function getFeedUI(posts, isMale) {
        return (posts) ? posts.map((post) => {
            const likedByUser = user && post.likes.map((val) => val.data()["userId"]).includes(user.userId);

            return (
                <div className="post-item mb-4" key={post.id}>
                    <div className="post-content">
                        <div className="post-author">
                            <div className="post-author-inner">
                                <div className="author-thumb">
                                    <a href={`/profile/${post.userId}`}><img src={post.user.profilePicture || "assets/images/member/profile/profile.jpg"} alt="profile" /></a>
                                </div>
                                <div className="author-details">
                                    <h6><a href={`/profile/${post.userId}`}>{post.user.fullName}</a></h6><div style={{marginRight: 15}} className="post-h-1">•</div>
                                    <span className="age-badge">
                                        {post.city}
                                    </span><div className="post-h-2" style={{ marginLeft: 15 }}>•</div>
                                    <ul className="post-status">
                                        <li style={{ marginLeft: 10, marginBottom: 5 }} className="post-time">{getTimeDifference(post.createdAt) + " önce"}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="post-description post-img-style">
                            <p>{post.caption}</p>
                            {
                                (post.images && post.images.length !== 0) ?
                                    post.images.length === 1 ? <div className="post-desc-img">
                                        <PostImage link={post.images[0]} />
                                    </div> :
                                        post.images.length === 2 ? <div className="post-desc-img">
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[0]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[1]} />
                                                </div>
                                            </div>
                                        </div> : post.images.length === 3 ? <div className="post-desc-img">
                                            <PostImage link={post.images[0]} />
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[1]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[2]} />
                                                </div>
                                            </div>
                                        </div> : post.images.length === 4 ? <div className="post-desc-img">
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[0]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[1]} />
                                                </div>
                                            </div>
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[2]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[3]} />
                                                </div>
                                            </div>
                                        </div> : <div className="post-desc-img">
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[0]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[1]} />
                                                </div>
                                            </div>
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[2]} />
                                                </div>
                                                <div className="col-md-6">
                                                    <PostImage link={post.images[3]} />
                                                </div>
                                            </div>
                                        </div>
                                    : null
                            }
                        </div>

                    </div>

                    <div className="post-meta">
                        <div className="post-meta-bottom">
                            <ul className="react-list">
                                <li className="react">
                                    <a style={{ color: likedByUser ? "red" : "" }} href="javascript:void(0);" onClick={() => likePost(post, likedByUser, isMale)}>
                                        <i style={{ color: likedByUser ? "red" : "" }} className="fa-solid fa-thumbs-up"></i>
                                        Beğen ({post.likes.length})
                                    </a>
                                </li>
                                <li className="react">
                                    <a href="javascript:void(0);" onClick={() => {
                                        if (isMale) {
                                            setMales((prevMales) =>
                                                prevMales.map((val) => val.id === post.id ? { ...val, commentsEnabled: !val.commentsEnabled } : val)
                                            );
                                        } else {
                                            setFemales((prevMales) =>
                                                prevMales.map((val) => val.id === post.id ? { ...val, commentsEnabled: !val.commentsEnabled } : val)
                                            );
                                        }
                                    }}>
                                        <i className="fa-solid fa-comment"></i>
                                        Yorumlar ({post.comments.length})
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {(post.commentsEnabled) ? <>

                        {/* Comments Section */}
                        <hr />
                        <div className="comments-section" style={{ maxHeight: 400, overflow: "auto" }}>
                            {post.comments.toSorted((function (a, b) { return new Date(b.createdAt.toDate()).getTime() - new Date(a.createdAt.toDate()).getTime() })).map((comment, index) => (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div className="comment-item" key={index}>
                                            <div className="comment-author">
                                                <img style={{ width: 30, height: 30, borderRadius: 50, marginRight: 15, marginLeft: 25, marginBottom: 5 }} src={comment.userPhoto || "assets/images/member/profile/profile.jpg"} alt="commentor" />
                                                <span style={{ marginRight: 10 }}><b>{comment.userName}</b></span>
                                            </div>
                                            <div style={{ maxWidth: 600, marginLeft: 20, overflow: "auto", fontSize: 14 }} className="comment-details">
                                                <span>{comment.comment}</span>
                                            </div>

                                        </div>
                                        <div style={{ display: "flex", flexGrow: 1 }}></div>
                                        <span style={{ marginRight: 25 }}>{getTimeDifference((new Date(comment.createdAt.toDate())).getTime()) + " önce"}</span>
                                    </div>
                                    <hr />
                                </>
                            ))}
                        </div>

                        {/* Comment Input */}
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: 15, paddingTop: (post.commentsEnabled && post.comments.length > 0) ? 15 : 0, marginInline: 15 }} className="comment-input">
                            <input value={comment} onChange={(e) => setComment(e.target.value)} style={{ marginRight: 15 }} type="text" placeholder="Yorum yap..." onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    addComment(post.id, e.target.value, isMale);
                                    setComment("")
                                }
                            }} />
                            <div style={{ display: "flex", flexGrow: 1 }}></div>
                            <input onClick={() => {
                                addComment(post.id, comment, isMale);
                                setComment("");
                            }} style={{ maxWidth: 130, maxHeight: 50 }} type="button" value="Gönder" className="default-btn" />
                        </div>
                    </> : <></>}
                </div>
            );
        }) : [];
    }

    async function setCityData(cityData) {
        setCity(cityData)
        setMales([])
        setFemales([])
        loadMoreFemalePosts(cityData)
        loadMoreMalePosts(cityData)
    }

    async function addComment(postId, caption, isMale) {
        if (!caption) return;
        if (!user) {
            window.location.href = "/login"
            return
        }
        const comment = {
            comment: caption,
            createdAt: Timestamp.fromDate(new Date()),
            userId: user.userId,
            userName: user.fullName,
            userPhoto: user.profilePicture
        }
        await addDoc(collection(firestore, `feeds/${postId}/comments/`),
            comment)
        
        if (isMale) {
            setMales(males.map((val) => val.id === postId ? {...val, comments: val.comments.concat([comment])} : val));
        } else {
            setFemales(females.map((val) => val.id === postId ? {...val, comments: val.comments.concat([comment])} : val));
        }
        
    }

    function citiesUI(citiesVal) {
        var i = 0
        return citiesVal.map((val) => {
            i += 1
            return <li className="nav-item" role="presentation">
                <button onClick={() => setCityData(val)} className={`nav-link ${(city === val) ? " active" : ""}`} id={`${val}-tab`} data-bs-toggle="tab" data-bs-target={`"#${val}"`} type="button" role="tab" aria-controls={`"${val}"`} aria-selected="true">{val}</button>
            </li >
        });
    }

    async function likePost(post, liked, ismale) {
        if (!user) {
            window.location.href = "/login"
            return
        }
        if (liked) {
            await deleteDoc(doc(firestore, `feeds/${post.id}/likes`, post.likes.filter((v) => v.data()["userId"] === user.userId)[0].id));
            if (ismale) {
                setMales(males.map((val) => val.id === post.id ? { ...val, likes: val.likes.filter((v) => v.data()["userId"] !== user.userId) } : val));
            } else {
                setFemales(females.map((val) => val.id === post.id ? { ...val, likes: val.likes.filter((v) => v.data()["userId"] !== user.userId) } : val));
            }
        } else {
            await setDoc(doc(firestore, `feeds/${post.id}/likes/${user.userId}`), { "userId": user.userId })
            if (ismale) {
                setMales(males.map((val) => val.id === post.id ? { ...val, likes: [...val.likes, { id: user.userId, data: () => { return { "userId": user.userId } } }] } : val));
            } else {
                setFemales(females.map((val) => val.id === post.id ? { ...val, likes: [...val.likes, { id: user.userId, data: () => { return { "userId": user.userId } } }] } : val));
            }
        }
    }

    useEffect(() => {
        getUserData(localStorage.getItem("user-id"))
    }, [])

    return (
        <Fragment>
            <HeaderOne />
            <div className="group group--single">
                <div className="group__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 d-none d-xl-block"></div>
                            <div className="col-xl-6">
                                <ul style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => selectGender(0)} style={{ fontSize: 18 }} className="nav-link active" id="females-tab" data-bs-toggle="tab" data-bs-target="#gt1" type="button" role="tab" aria-controls="gt1" aria-selected="true"><i className="fa-solid fa-venus"></i> Kadın</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button onClick={() => selectGender(1)} style={{ fontSize: 18 }} className="nav-link" id="males-tab" data-bs-toggle="tab" data-bs-target="#gt4" type="button" role="tab" aria-controls="gt4" aria-selected="false"><i className="fa-solid fa-mars"></i> Erkek</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-3 d-none d-xl-block"></div>
                        </div>
                    </div>
                </div>

                <br />

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ maxWidth: 800, maxHeight: 100, overflow: "auto" }} className="group__bottom--head group__bottom--activity bg-white mb-4 border-0">
                        <ul className="nav nav-tabs" id="myTab2" role="tablist">
                            {cities ? citiesUI(cities) : <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="Personal-tab" data-bs-toggle="tab" data-bs-target="#Personal" type="button" role="tab" aria-controls="Personal" aria-selected="true">Şehirler yükleniyor..</button>
                            </li>}
                        </ul>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><div className="group__bottom--area group__bottom--memberactivity" style={
                    {
                        maxWidth: 800
                    }
                }>
                    <div className="group__bottom--body">
                        <div className="group__bottom--allmedia">
                            <div className="media-wrapper">
                                <div className="tab-content" id="myTabContent2">
                                    <div className="tab-pane fade show active" id="Personal"
                                        role="tabpanel" aria-labelledby="Personal-tab">
                                        <div className="create-post mb-4" style={{ display: (((males && females && males.length > 0 && females.length > 0) || loading) && user) ? "" : "none" }} >
                                            <div className="lab-inner">
                                                <div className="lab-thumb">
                                                    <div className="thumb-inner">
                                                        <div className="thumb-img">
                                                            <img src={user ? user.profilePicture : "assets/images/member/profile/profile.jpg"} alt="datting thumb" />
                                                        </div>
                                                        <div className="thumb-content">
                                                            <h6><a href="#"> {user ? user.fullName : ""}</a></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lab-content">
                                                    <form action="#" className="post-form">
                                                        {(selectedPhotoLinks.length > 0) ? <div>
                                                            {selectedPhotoLinks.map((val) => {
                                                                return <img src={val} style={{ maxHeight: 200, margin: 20, borderRadius: 15 }}></img>
                                                            })}
                                                        </div> : <></>}
                                                        <input className="input-post" value={caption} onChange={(e) => setCaption(e.target.value)} type="text" placeholder="Ne paylaşmak istersin?" />
                                                        <div style={{display: city ? "none" : "", paddingInline: 22, paddingBottom: 5, fontSize: 15}}>(Paylaşımını hazırlamadan önce yukarıdan şehir seç!)</div>
                                                        <div className="content-type">
                                                            <ul className="content-list">
                                                                <li className="text"><a href="#"><i className="fa-solid fa-pen-to-square"></i>Yazı </a></li>
                                                                <li className="image-video">
                                                                    <div className="file-btn"><i className="fa-solid fa-camera"></i>Fotoğraf</div>
                                                                    <input
                                                                        type="file"
                                                                        multiple="multiple"
                                                                        onChange={(e) => {
                                                                            const files = Array.from(e.target.files);
                                                                            const links = [];
                                                                            files.forEach((file) => {
                                                                                const reader = new FileReader();
                                                                                reader.onload = (event) => {
                                                                                    links.push(event.target.result);
                                                                                    if (links.length === files.length) {
                                                                                        setSelectedPhotoLinks(links);
                                                                                    }
                                                                                };
                                                                                reader.readAsDataURL(file);
                                                                            });
                                                                            setSelectedPhotos(files);
                                                                        }}
                                                                    />

                                                                </li>
                                                                <li className="post-submit">
                                                                    <input onClick={async () => {
                                                                        setLoading(true)
                                                                        await submitPost(user, selectedPhotos, caption, city, () => {
                                                                            setCaption("")
                                                                            setSelectedPhotoLinks([])
                                                                            setSelectedPhotos([])
                                                                        }, () => { navigate("/premium") })
                                                                        setLoading(false)
                                                                    }} type="button" value="Paylaş" className="default-btn" />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: "none" }} id="male_feeds">{getFeedUI(males, true)}</div>

                                        <div id="female_feeds">{getFeedUI(females, false)}</div>

                                        <div id="loading" style={{ display: (!loadingMore) ? "none" : "flex", justifyContent: "center", alignItems: "center", minHeight: 300 }}>

                                            <span className="loader"></span>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default HomePage;