import { Fragment, useEffect, useState } from "react";
import HeaderOne from "../component/layout/header";
import Footer from "../component/layout/footer";
import '../assets/css/extra/premium.css';
import { getUser } from "../scripts/auth/user_data";
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { firestore } from "../scripts/main_firebase";

const title = "Abonelikler";

const Premium = () => {
    const [user, setUser] = useState(null);
    const [hideShopier, setHideShopier] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showDialog2, setShowDialog2] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedPackage2, setSelectedPackage2] = useState(null);
    const [settings, setSettings] = useState(null);
    const [sendingUser, setSendingUser] = useState("");
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [loadingApproval, setLoadingApproval] = useState(false);
    const [agreeToTerms2, setAgreeToTerms2] = useState(false);
    const [loadingApproval2, setLoadingApproval2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showApproveButton, setShowApproveButton] = useState(false);

    async function getUserData(uid) {
        const userData = await getUser(uid);
        setUser(userData);
        const settingsData = await getDoc(doc(firestore, "settings/settings"));

        if (!settingsData.exists()) return; // Ensure the document exists

        setHideShopier(!settingsData.data()["shopierAvailable"]);
        setSettings(settingsData.data());

        const cantShowApproveButtonData = await getDocs(query(collection(firestore, "payments"), where("userId", "==", uid), where("approve", "==", false)));

        setShowApproveButton(cantShowApproveButtonData.empty);

        // Set packages based on fetched settings
        const updatedPackages = [
            {
                ...packages[0],
                price: settingsData.data()["2monthly"],
                id: settingsData.data()["2monthShopierId"]
            },
            {
                ...packages[1],
                price: settingsData.data()["4monthly"],
                id: settingsData.data()["4monthShopierId"]
            },
            {
                ...packages[2],
                price: settingsData.data()["6monthly"],
                id: settingsData.data()["6monthShopierId"]
            }
        ];

        setPackages(updatedPackages); // Update the packages
    }


    const [packages, setPackages] = useState([
        { "title": "2 Aylık Abonelik", "price": 399, "name": "2monthly", id: null },
        { "title": "4 Aylık Abonelik", "price": 590, "name": "4monthly", id: null },
        { "title": "6 Aylık Abonelik", "price": 790, "name": "6monthly", id: null }
    ]);

    function loadJS() {
        const script1 = document.createElement('script');
        script1.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js';
        script1.async = true;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js';
        script2.async = true;
        document.body.appendChild(script2);

        const script3 = document.createElement('script');
        script3.src = 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.3/js/bootstrap.min.js';
        script3.async = true;
        document.body.appendChild(script3);

        const script4 = document.createElement('script');
        script4.src = 'https://s3.eu-central-1.amazonaws.com/shopier/framework.js';
        script4.async = true;
        document.body.appendChild(script4);
    }

    useEffect(() => {
        loadJS();
        getUserData(localStorage.getItem("user-id"));
    }, []);

    useEffect(() => {
        // Set loading to false only when packages are updated
        if (packages.length > 0) {
            setLoading(false);
            console.log(packages); // You can still log the packages here
        }
    }, [packages]);

    function openDialog(pac) {
        if (!user) {
            window.location.href = "../login";
            return;
        }
        setSelectedPackage(pac);
        setShowDialog(true);
    }

    function closeDialog() {
        setShowDialog(false);
        setSelectedPackage(null);
        setAgreeToTerms(false);
    }

    function openDialog2(pac) {
        if (!showApproveButton) {
            alert("Ödemeniz inceleniyor!");
            return;
        }
        if (!user) {
            window.location.href = "../login";
            return;
        }
        setSelectedPackage2(pac);
        setShowDialog2(true);
    }

    function closeDialog2() {
        setShowDialog2(false);
        setSelectedPackage2(null);
        setAgreeToTerms2(false);
    }

    async function sendForApproval() {
        if (!sendingUser || !user) {
            return;
        }
        if (!agreeToTerms) {
            alert("Kullanim Şartları ve Gizlilik Politikasını kabul ediyorum tikini işaretlemeniz gerekmektedir");
            return;
        }
        setLoadingApproval(true)
        await addDoc(collection(firestore, "payments"), {
            "userId": user.userId,
            "name": sendingUser,
            "product": selectedPackage.name,
            "approve": false,
            "timestamp": Timestamp.now()
        });
        alert("Ödemeniz en kısa sürede onaylanacaktır");
        setLoadingApproval(false);
        closeDialog();
    }

    async function sendForApproval2() {
        if (!sendingUser || !user) {
            return;
        }
        if (!agreeToTerms2) {
            alert("Kullanim Şartları ve Gizlilik Politikasını kabul ediyorum tikini işaretlemeniz gerekmektedir");
            return;
        }
        setLoadingApproval(true)
        await addDoc(collection(firestore, "payments"), {
            "userId": user.userId,
            "name": sendingUser,
            "product": selectedPackage2.name,
            "approve": false,
            "timestamp": Timestamp.now(),
            "type": "shopier",
            "package_id": selectedPackage2.id
        });
        alert("Ödemeniz en kısa sürede onaylanacaktır");
        setLoadingApproval(false);
        closeDialog();
    }


    function copyToClipboard(text) {
        navigator.clipboard.writeText(text);
        alert("Kopyalandı: " + text);
    }

    if (loading) {
        return <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <span className="loader" style={{ width: 48, height: 48 }}></span>
        </div>
    }

    return (
        <Fragment>
            <HeaderOne />
            <div style={{ paddingTop: 30 }} className="membership padding-bottom">
                <div className="container">
                    <div className="section__header style-2 text-center">
                        <h2>{title}</h2>
                        <p style={{ fontSize: 18, color: "red" }}>Shopier ile abonelik aldıktan sonra aldığınız ürün altındaki "Satın Aldım" butonuna tıklayıp bilgileri doldurmanız gerekmektedir!</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            {packages.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="membership__item">
                                        <div className="membership__inner">
                                            <div className="membership__head">
                                                <h4>{val.title}</h4>
                                                <p>{val.price + " TL"}</p>
                                            </div>
                                            <div className="membership__footer">
                                                {<button onClick={() => {
                                                    if (!user) {
                                                        window.location.href = "../login"
                                                        return;
                                                    }
                                                }} style={{ marginInline: 10, display: (hideShopier) ? "none" : "", marginBottom: 15 }} className="default-btn reverse shopier premium-component" id={val.id}><span>Satın Al</span></button>}
                                                <button style={{ marginBottom: 15, marginInline: 10 }} onClick={() => openDialog(val)} className="default-btn premium-component"><span>İbanla Satın Al</span></button>
                                                <button style={{ marginInline: 1 }} onClick={() => openDialog2(val)} className="default-btn premium-component"><span>Satın Aldım</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {showDialog && selectedPackage && settings && (
                <div className="dialog-overlay">
                    <div className="dialog">
                        <h3>Ödeme Ekranı</h3>
                        <p>
                            <strong>Alıcı Ad Soyad:</strong> {settings.name}
                            <a className="fa-solid fa-copy" onClick={() => copyToClipboard(settings.name)} style={{ cursor: "pointer", marginLeft: "5px", color: "#333" }} />
                        </p>
                        <p>
                            <strong>IBAN:</strong> {settings.iban}
                            <a className="fa-solid fa-copy" onClick={() => copyToClipboard(settings.iban)} style={{ cursor: "pointer", marginLeft: "5px", color: "#333" }} />
                        </p>
                        <p>
                            <strong>Referans:</strong> {user.userId}
                            <a className="fa-solid fa-copy" onClick={() => copyToClipboard(user.userId)} style={{ cursor: "pointer", marginLeft: "5px", color: "#333" }} />
                        </p>
                        <p><strong>Ödemeniz Gereken Tutar:</strong> {selectedPackage.price} TL</p>
                        <input value={sendingUser} onChange={(ev) => setSendingUser(ev.target.value)} type="text" placeholder="Gönderen İsim Soyisim" className="sender-name-input" />

                        <div style={{ marginTop: "10px", textAlign: "left", display: "flex", flexDirection: "row" }}>
                            <input
                                type="checkbox"
                                id="terms"
                                checked={agreeToTerms}
                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                                style={{ width: 20, height: 20 }}
                            />
                            <label htmlFor="terms" style={{ marginLeft: "5px" }}>
                                <a href="https://docs.google.com/document/d/1h-JlXJRgxue4jnMhhZ6X23WLAWzBZDSTmidU2fiLhIE/edit?tab=t.0" target="_blank" rel="noopener noreferrer" style={{ color: "#f24570" }}> Gizlilik Sözleşmesi </a> ve <span> </span>
                                <a href="https://docs.google.com/document/d/1CXXOALJneFp8cW7dxrXuQyzCz8GvjGuo8qXWhJ0qpWc/edit?tab=t.0" target="_blank" rel="noopener noreferrer" style={{ color: "#f24570" }}> Kullanım Sözleşmesini </a> kabul ediyorum
                            </label>
                        </div>

                        <button
                            className="default-btn-0"
                            onClick={() => sendForApproval()}
                            style={{ marginTop: "10px" }}
                        >
                            {(loadingApproval) ? <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30 }}>
                                <span className="loader" style={{ width: 30, height: 30 }}></span>
                            </div> : "Ödeme Yaptım"}
                        </button>
                        <button className="close-btn" onClick={closeDialog}>Kapat</button>
                    </div>
                </div>
            )}

            {showDialog2 && selectedPackage2 && settings && (
                <div className="dialog-overlay">
                    <div className="dialog small">
                        <h3>Shopier Paket Alım Onayı</h3>
                        <p><strong>Ödediğiniz Paket:</strong> {selectedPackage2.title}</p>
                        <input value={sendingUser} onChange={(ev) => setSendingUser(ev.target.value)} type="text" placeholder="Gönderen İsim Soyisim" className="sender-name-input" />

                        <div style={{ marginTop: "10px", textAlign: "left", display: "flex", flexDirection: "row" }}>
                            <input
                                type="checkbox"
                                id="terms"
                                checked={agreeToTerms2}
                                onChange={() => setAgreeToTerms2(!agreeToTerms2)}
                                style={{ width: 20, height: 20 }}
                            />
                            <label htmlFor="terms" style={{ marginLeft: "5px" }}>
                                <a href="https://docs.google.com/document/d/1h-JlXJRgxue4jnMhhZ6X23WLAWzBZDSTmidU2fiLhIE/edit?tab=t.0" target="_blank" rel="noopener noreferrer" style={{ color: "#f24570" }}> Gizlilik Sözleşmesi </a> ve <span> </span>
                                <a href="https://docs.google.com/document/d/1CXXOALJneFp8cW7dxrXuQyzCz8GvjGuo8qXWhJ0qpWc/edit?tab=t.0" target="_blank" rel="noopener noreferrer" style={{ color: "#f24570" }}> Kullanım Sözleşmesini </a> kabul ediyorum
                            </label>
                        </div>

                        <button
                            className="default-btn-0"
                            onClick={() => sendForApproval2()}
                            style={{ marginTop: "10px" }}
                            disabled={loadingApproval2}
                        >
                            {(loadingApproval2) ? <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 30 }}>
                                <span className="loader" style={{ width: 30, height: 30 }}></span>
                            </div> : "Ödeme Yaptım"}
                        </button>
                        <button className="close-btn" onClick={closeDialog2}>Kapat</button>
                    </div>
                </div>
            )}

            <Footer />
        </Fragment>
    );
};

export default Premium;
