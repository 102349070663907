import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';

const newstitle = "Newsletter Sign up";
const jointitle = "Join Community";
const infotitle = "Our Information";
const accountTitle = "My Account";
const helpTitle = "Help Center";
const communityTitle = "Community";


let SocialList = [
    {
        iconName: 'fa-brands fa-twitter',
        visitLink: '#',
    },
    {
        iconName: 'fa-brands fa-twitch',
        visitLink: '#',
    },
    {
        iconName: 'fa-brands fa-instagram',
        visitLink: '#',
    },
    {
        iconName: 'fa-brands fa-dribbble',
        visitLink: '#',
    },
    {
        iconName: 'fa-brands fa-facebook-messenger',
        visitLink: '#',
    },
]

let InfoList = [
    {
        pageName: 'About Us',
        pageLink: '/about',
    },
    {
        pageName: 'Contact Us',
        pageLink: '/contact',
    },
    {
        pageName: 'Customer Reviews',
        pageLink: '#',
    },
    {
        pageName: 'Success Stories',
        pageLink: '#',
    },
    {
        pageName: 'Business License',
        pageLink: '#',
    },
]

let InfoListTwo = [
    {
        pageName: 'Manage Account',
        pageLink: '#',
    },
    {
        pageName: 'Safety Tips',
        pageLink: '#',
    },
    {
        pageName: 'Account Varification',
        pageLink: '#',
    },
    {
        pageName: 'Safety and Security',
        pageLink: '#',
    },
    {
        pageName: 'Membership Level',
        pageLink: '#',
    },
]

let InfoListThree = [
    {
        pageName: 'Help center',
        pageLink: '#',
    },
    {
        pageName: 'FAQ',
        pageLink: '#',
    },
    {
        pageName: 'Quick Start Guide',
        pageLink: '#',
    },
    {
        pageName: 'Tutorials',
        pageLink: '#',
    },
    {
        pageName: 'Associate Blog',
        pageLink: '#',
    },
]

let InfoListFour = [
    {
        pageName: 'Privacy policy',
        pageLink: '/policy',
    },
    {
        pageName: 'End User Agreements',
        pageLink: '#',
    },
    {
        pageName: 'Refund Policy',
        pageLink: '#',
    },
    {
        pageName: 'Cookie policy',
        pageLink: '#',
    },
    {
        pageName: 'Report abuse',
        pageLink: '#',
    },
]

function Footer() {

    const [newsEmail, setNewsEmail] = useState('');

    const handleEmailChange = (e) => {
        setNewsEmail(e.target.value);
    };

    return (
        <footer className="footer overflow-hidden">
            <div className="footer__bottom wow fadeInUp" data-wow-duration="1.5s">
                <div className="container">
                    <div className="footer__content text-center">
                        <p className="mb-0">Sitenin <a href="https://docs.google.com/document/d/1h-JlXJRgxue4jnMhhZ6X23WLAWzBZDSTmidU2fiLhIE/edit?tab=t.0"> Gizlilik Sözleşmesi </a> ve <a href="https://docs.google.com/document/d/1CXXOALJneFp8cW7dxrXuQyzCz8GvjGuo8qXWhJ0qpWc/edit?tab=t.0"> Kullanıcı Sözleşmesi </a></p>
                        <p className="mb-0"><a href="https://docs.google.com/document/d/1JzYXPw6uWrY-t72ILs_6qsMSLYOqqa4DJtb2jSKq_8o/edit?tab=t.0"> Geri Ödeme Politikası </a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
