export class FeedModel {
    constructor({
      id,
      userId,
      createdAt,
      gender,
      caption = null,
      city = null,
      user = null,
      status,
      images,
      likes
    }) {
      this.id = id;
      this.userId = userId;
      this.createdAt = createdAt;
      this.caption = caption;
      this.gender = gender;
      this.city = city;
      this.user = user;
      this.status = status;
      this.images = images;
      this.likes = likes;
    }
  
    copyWith({
      id,
      userId,
      createdAt,
      gender,
      caption,
      city,
      user,
      status,
      images,
      likes
    }) {
      return new FeedModel({
        id: id ?? this.id,
        userId: userId ?? this.userId,
        createdAt: createdAt ?? this.createdAt,
        gender: gender ?? this.gender,
        caption: caption ?? this.caption,
        city: city ?? this.city,
        user: user ?? this.user,
        status: status ?? this.status,
        images: images ?? this.images,
        likes: likes ?? this.likes
      });
    }
  
    toMap() {
      return {
        id: this.id,
        userId: this.userId,
        createdAt: this.createdAt.toDate().getTime(), // milliseconds since epoch
        caption: this.caption,
        images: this.images,
        gender: this.gender,
        city: this.city,
        status: this.status,
        likes: this.likes
      };
    }
  
    static fromMap(map) {
      return new FeedModel({
        id: map.id || '',
        userId: map.userId || '',
        city: map.city || '',
        user: map.user || null,
        status: map.status || '',
        gender: map.gender || 'male',
        createdAt: new Date(map.createdAt),
        caption: map.caption || null,
        images: map.images || [],
        likes: map.likes || []
      });
    }
  
    toJson() {
      return JSON.stringify(this.toMap());
    }
  
    static fromJson(source) {
      return FeedModel.fromMap(JSON.parse(source));
    }
  
    toString() {
      return `FeedModel(id: ${this.id}, userId: ${this.userId}, createdAt: ${this.createdAt}, caption: ${this.caption}, images: ${this.images}, likes: ${this.likes})`;
    }
  }
  