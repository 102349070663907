import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, firestore } from "../main_firebase";
import { doc, setDoc } from 'firebase/firestore';

export const uploadFileToStorage = async (file, folder) => {
  const storageRef = ref(storage, `${folder}/${file.name}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

export async function reportUser(userId, details, image, currentUserId) {
  const time = new Date().getTime();
  const reportModel = {
    id: `${userId}report${time}`,
    createdAt: time,
    images: [],
    reason: details,
    reportedByUserId: currentUserId,
    reportedUserId: userId
  }
  console.log(reportModel)
  console.log(image)
  const result = await reportUserFunction(reportModel, image);
  return result;
}

// Function to report a user
async function reportUserFunction(reportModel, image) {
  try {
    // Upload report images and get their URLs
    const uploadedImageUrls = await uploadReportImages([image], reportModel.reportingUserId);

    // Create a new report model with the uploaded image URLs
    const newReportModel = { ...reportModel, images: uploadedImageUrls };

    // Save the report to Firestore
    await setDoc(doc(firestore, 'reports', newReportModel.id), newReportModel);

    return true;
  } catch (error) {
    console.error("Error reporting user:", error);
    return false;
  }
}

// Function to upload report images
async function uploadReportImages(files, userId) {
  try {
    const urls = [];

    for (const file of files) {
      const currentTime = Date.now();
      const fileName = `${currentTime}_${userId}_${file.name}`;

      const fileRef = ref(storage, `reports/${userId}/${fileName}`);
      await uploadBytes(fileRef, file);

      const url = await getDownloadURL(fileRef);
      if (url) {
        urls.push(url);
      }
    }

    return urls;
  } catch (error) {
    console.error("Error uploading report images:", error);
    return [];
  }
}
