import { UserAccountSettingsModel } from "./user_details";

export class UserProfileModel {
    constructor({
        id,
        userId,
        fullName,
        email = null,
        profilePicture = null,
        phoneNumber = null,
        gender,
        about = null,
        whatsapp = null,
        instagram = null,
        purchaseStatus,
        bot,
        isForeign,
        birthDay,
        mediaFiles,
        interests,
        userAccountSettingsModel,
        isVerified,
        isOnline = false,
    }) {
        this.id = id;
        this.userId = userId;
        this.fullName = fullName;
        this.email = email;
        this.profilePicture = profilePicture;
        this.phoneNumber = phoneNumber;
        this.gender = gender;
        this.about = about;
        this.whatsapp = whatsapp;
        this.instagram = instagram;
        this.purchaseStatus = purchaseStatus;
        this.bot = bot;
        this.isForeign = isForeign;
        this.birthDay = new Date(birthDay);
        this.mediaFiles = mediaFiles;
        this.interests = interests;
        this.userAccountSettingsModel = userAccountSettingsModel;
        this.isVerified = isVerified;
        this.isOnline = isOnline;
    }

    copyWith({
        id,
        userId,
        fullName,
        email,
        profilePicture,
        phoneNumber,
        gender,
        about,
        whatsapp,
        instagram,
        purchaseStatus,
        bot,
        isForeign,
        birthDay,
        mediaFiles,
        interests,
        userAccountSettingsModel,
        isVerified,
        isOnline,
    }) {
        return new UserProfileModel({
            id: id || this.id,
            userId: userId || this.userId,
            fullName: fullName || this.fullName,
            email: email || this.email,
            profilePicture: profilePicture || this.profilePicture,
            phoneNumber: phoneNumber || this.phoneNumber,
            gender: gender || this.gender,
            about: about || this.about,
            whatsapp: whatsapp || this.whatsapp,
            instagram: instagram || this.instagram,
            purchaseStatus: purchaseStatus !== undefined ? purchaseStatus : this.purchaseStatus,
            bot: bot !== undefined ? bot : this.bot,
            isForeign: isForeign !== undefined ? isForeign : this.isForeign,
            birthDay: birthDay || this.birthDay,
            mediaFiles: mediaFiles || this.mediaFiles,
            interests: interests || this.interests,
            userAccountSettingsModel: userAccountSettingsModel || this.userAccountSettingsModel,
            isVerified: isVerified !== undefined ? isVerified : this.isVerified,
            isOnline: isOnline !== undefined ? isOnline : this.isOnline,
        });
    }

    toMap() {
        const result = {
            id: this.id,
            userId: this.userId,
            fullName: this.fullName,
            gender: this.gender,
            purchaseStatus: this.purchaseStatus,
            bot: this.bot,
            isForeign: this.isForeign,
            birthDay: this.birthDay.getTime(),
            mediaFiles: this.mediaFiles,
            interests: this.interests,
            isVerified: this.isVerified,
            isOnline: this.isOnline,
        };

        if (this.email) result.email = this.email;
        if (this.profilePicture) result.profilePicture = this.profilePicture;
        if (this.phoneNumber) result.phoneNumber = this.phoneNumber;
        if (this.about) result.about = this.about;
        if (this.whatsapp) result.whatsapp = this.whatsapp;
        if (this.instagram) result.instagram = this.instagram;

        result.userAccountSettingsModel = this.userAccountSettingsModel.toMap();
        return result;
    }

    static fromMap(map) {
        return new UserProfileModel({
            id: map.id || '',
            userId: map.userId || '',
            fullName: map.fullName || '',
            email: map.email,
            profilePicture: map.profilePicture,
            phoneNumber: map.phoneNumber,
            gender: map.gender || '',
            about: map.about,
            whatsapp: map.whatsapp,
            instagram: map.instagram,
            purchaseStatus: map.purchaseStatus || false,
            bot: map.bot || false,
            isForeign: map.isForeign || false,
            birthDay: new Date(map.birthDay),
            mediaFiles: Array.isArray(map.mediaFiles) ? map.mediaFiles : [],
            interests: Array.isArray(map.interests) ? map.interests : [],
            userAccountSettingsModel: UserAccountSettingsModel.fromMap(map.userAccountSettingsModel),
            isVerified: map.isVerified || false,
            isOnline: map.isOnline || false,
        });
    }

    toJson() {
        return JSON.stringify(this.toMap());
    }

    static fromJson(jsonString) {
        return UserProfileModel.fromMap(JSON.parse(jsonString));
    }

    toString() {
        return `UserProfileModel(id: ${this.id}, userId: ${this.userId}, fullName: ${this.fullName}, email: ${this.email}, profilePicture: ${this.profilePicture}, phoneNumber: ${this.phoneNumber}, gender: ${this.gender}, about: ${this.about}, whatsapp: ${this.whatsapp}, instagram: ${this.instagram}, birthDay: ${this.birthDay}, mediaFiles: ${this.mediaFiles}, interests: ${this.interests}, userAccountSettingsModel: ${this.userAccountSettingsModel}, isVerified: ${this.isVerified}, isOnline: ${this.isOnline})`;
    }

    equals(other) {
        if (!(other instanceof UserProfileModel)) return false;

        const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

        return this.id === other.id &&
            this.userId === other.userId &&
            this.fullName === other.fullName &&
            this.email === other.email &&
            this.profilePicture === other.profilePicture &&
            this.phoneNumber === other.phoneNumber &&
            this.gender === other.gender &&
            this.about === other.about &&
            this.purchaseStatus === other.purchaseStatus &&
            this.whatsapp === other.whatsapp &&
            this.instagram === other.instagram &&
            this.birthDay.getTime() === other.birthDay.getTime() &&
            deepEqual(this.mediaFiles, other.mediaFiles) &&
            deepEqual(this.interests, other.interests) &&
            this.userAccountSettingsModel.equals(other.userAccountSettingsModel) &&
            this.isVerified === other.isVerified &&
            this.isOnline === other.isOnline;
    }

    hashCode() {
        return [
            this.id,
            this.userId,
            this.fullName,
            this.email,
            this.profilePicture,
            this.phoneNumber,
            this.gender,
            this.about,
            this.purchaseStatus,
            this.whatsapp,
            this.instagram,
            this.birthDay.getTime(),
            this.mediaFiles.join(','),
            this.interests.join(','),
            this.userAccountSettingsModel.hashCode(),
            this.isVerified,
            this.isOnline
        ].join('|').hashCode();
    }
}
