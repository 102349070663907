import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { auth } from '../../scripts/main_firebase';
import { getUser } from '../../scripts/auth/user_data';
import Logo from './logo';

function HeaderOne({ assetPrefix = "" }) {

    const [user, setUser] = useState(null)

    async function getUserData(uid) {
        const userData = await getUser(uid)
        setUser(userData)
    }

    useEffect(() => {
        auth.onAuthStateChanged((userValue) => {
            if (userValue) {
                getUserData(userValue.uid)
            } else {
                setUser(null);
            }
        });
    }, [setUser]);


    useEffect(() => {
        const handleScroll = () => {
            const value = window.scrollY;
            const header = document.querySelector('.header');
            if (value > 200) {
                header.classList.add('header-fixed', 'animated', 'fadeInDown');
            } else {
                header.classList.remove('header-fixed', 'animated', 'fadeInDown');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    async function logOut() {
        localStorage.clear()
        await auth.signOut()
        window.location.href = "/login"
    }

    return (
        <header className="header" id="navbar">
            <div className="header__bottom">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand" to="/">
                            <Logo />
                        </Link>
                        {/*
                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler--icon"></span>
                        </button>*/}
                        <div className="collapse navbar-collapse justify-content-end collapse show" id="navbarNavAltMarkup">
                            <div className="navbar-nav mainmenu">
                                <ul>
                                    <li><NavLink to="/">İlanlar</NavLink></li>
                                    <li><NavLink to="#" onClick={() => {
                                        window.location.href = (localStorage.getItem("user-id") && auth.currentUser) ? "/home" : "/login"
                                    }}>Yakındakiler</NavLink></li>
                                    <li><NavLink to="/chats">Sohbetler</NavLink></li>
                                    <li><NavLink to="/premium">Premium</NavLink></li>
                                    <li><a href="https://play.google.com/store/apps/details?id=hira.dating.friendship.com">⬇️ Uygulamamızı İndirin</a></li>
                                </ul>
                            </div>
                            <div className="header__more">
                                <button className="default-btn dropdown-toggle" type="button" id="moreoption" data-bs-toggle="dropdown" aria-expanded="false">
                                    {user && <img src={user.profilePicture} style={{
                                        marginRight: 6, borderRadius: 30,
                                        height: 30,
                                        width: 30,
                                    }} ></img>}
                                    {(user) ? user.fullName : "Hesabım"}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="moreoption">
                                    {user ?
                                        <>
                                            <li><Link className="dropdown-item" to="/edit-profile">Profilimi Düzenle</Link></li>
                                            <li><a style={{cursor: "pointer"}} className="dropdown-item" onClick={() => logOut()}>Çıkış Yap</a></li>
                                        </> :
                                        <>
                                            <li><Link className="dropdown-item" to="/login">Giriş Yap</Link></li>
                                            <li><Link className="dropdown-item" to="/register">Üye Ol</Link></li>
                                        </>}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div >
        </header >
    );
};

export default HeaderOne;
