import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../main_firebase";
import { doc, setDoc } from 'firebase/firestore';

export async function login(email, password) {
    try {
        const result = await signInWithEmailAndPassword(auth, email, password)
        const user = result.user
        await setDoc(doc(firestore, `userLogin/${user.uid}`), {
            "loggedIn": true,
            "id": user.uid,
            "time": Date.now(),
        })
        localStorage.setItem("user-id", user.uid)
        return true
    } catch (error) {
        console.error(error.code, error.message)
        alert(error.message)
        return false
    }
}