import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from 'firebase/firestore';
import { UserProfileModel } from "../../lib/models/user";
import { AppConfig } from "../../lib/common/appconfig";
import { auth, storage } from "../main_firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { UserAccountSettingsModel, UserLocation } from "../../lib/models/user_details";
import { firestore } from "../main_firebase";

export async function editProfile(imageFile, name, birthday, gender, location, phone, instagram, whatsapp, user) {
    try {
        if (!name || !birthday || !gender || !location) throw ("Eksik bilgi!")

        const userId = auth.currentUser.uid
        if (!userId || !user) throw ("Kullanıcı bulunamadı")

        //upload pp

        var userProfilePic = user.profilePicture

        if (imageFile) {
            const profilePictureRef = ref(storage, `user_profile_pictures/${userId}.png`);
            await uploadBytes(profilePictureRef, imageFile);
            const ppDownloadUrl = await getDownloadURL(profilePictureRef)
            userProfilePic = ppDownloadUrl;
        }

        const userAccountSettingsModel =
            new UserAccountSettingsModel({
                location: new UserLocation(location.latitude, location.longitude),
                maximumAge: user.userAccountSettingsModel.maximumAge,
                minimumAge: user.userAccountSettingsModel.minimumAge,
            });

        const userProfileModel = new UserProfileModel({
            id: userId,
            userId: userId,
            fullName: name,
            mediaFiles: user.mediaFiles,
            interests: user.interests,
            gender: gender,
            purchaseStatus: user.purchaseStatus,
            bot: user.bot,
            isForeign: user.isForeign,
            profilePicture: userProfilePic,
            birthDay: birthday,
            email: user.email,
            phoneNumber: phone,
            userAccountSettingsModel: userAccountSettingsModel,
            isVerified: user.isVerified,
            instagram: instagram,
            whatsapp: whatsapp
        });

        await setDoc(doc(firestore, `userProfile/${user.id}`), userProfileModel.toMap())
        return true
    } catch (error) {
        console.error(error.code, error.message)
        alert(error.message)
        return false
    }
}