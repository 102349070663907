import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD0CiNhD_N9fjUUz8m-PuKJg8P24EyIwl0",
  authDomain: "hiradating.firebaseapp.com",
  databaseURL: "https://hiradating-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hiradating",
  storageBucket: "hiradating.appspot.com",
  messagingSenderId: "177244738008",
  appId: "1:177244738008:web:d96f417489b40a26dd22ad",
  measurementId: "G-6P95QW2ZHD"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);