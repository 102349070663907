import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../assets/css/extra/signup.css';
import { signUp } from "../scripts/auth/signup";
import Footer from "../component/layout/footer";
import Logo from "../component/layout/logo";

const title = "Hoşgeldin";
const desc = "Hadi hesabını oluşturalım! Aşağıdaki formu doldurarak hesabını oluştur.";
const accTitle = "Hesap Detayları";

const SignUp = () => {
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regConPassword, setRegConPassword] = useState('');

    const [regName, setRegName] = useState('');
    const [birthday, setBirthday] = useState(0);

    const [location, setLocation] = useState({ latitude: "", longitude: "" });
    const [locationErrorMessage, setLocationErrorMessage] = useState("");

    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePicURI, setProfilePicURI] = useState(null)

    const navigate = useNavigate();

    const wn = (val) => { return (val.length > 0) ? val : null }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(location)
        const ok = await signUp(profilePicture, wn(regEmail), wn(regPassword), wn(regConPassword), wn(regName), birthday === 0 ? null : birthday, getSelectedGender(), (location.latitude && location.longitude) ? location : null)
        if (ok) {
            navigate("../");
        }
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfilePicURI(reader.result);
            };
            reader.readAsDataURL(file);
            setProfilePicture(file)
        }
    };

    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                    console.log({ latitude, longitude })
                    setLocationErrorMessage(""); // Reset error message if successful
                },
                (error) => {
                    setLocationErrorMessage("Konumunuz alınamadı. Lütfen konum erişimine izin verin.");
                }
            );
        } else {
            setLocationErrorMessage("Bu tarayıcı coğrafi konumu desteklemiyor.");
        }
    };

    const handleBirthdayChange = (e) => {
        const date = new Date(e.target.value)
        setBirthday(date.getTime())
    }

    function getSelectedGender() {
        const maleRadio = document.getElementById("males1");
        const femaleRadio = document.getElementById("females1");
    
        if (maleRadio.checked) {
            return "male";
        } else if (femaleRadio.checked) {
            return "female";
        } else {
            return null; // No gender selected
        }
    }    

    return (
        <section className="log-reg">
            <div className="top-menu-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-7">
                            <div className="logo">
                                <Logo />
                            </div>
                        </div>
                        <div className="col-lg-4 col-5">
                            <Link to="/" className="backto-home"><i className="fas fa-chevron-left"></i> Ana Sayfaya Geri Dön</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="image"></div>
                    <div className="col-lg-7">
                        <div className="log-reg-inner">
                            <div className="section-header">
                                <h2 className="title">{title}</h2>
                                <p>{desc}</p>
                            </div>
                            <div className="main-content">
                                <form action="#">
                                    <h4 className="content-title">{accTitle}</h4>
                                    <div className="form-group">
                                        <label>Profil Fotoğrafı*</label>
                                        <div className="profile-picture-selector" onClick={() => document.getElementById('fileInput').click()}>
                                            {profilePicURI ? (
                                                <img
                                                    src={profilePicURI}
                                                    alt="Profile Preview"
                                                    className="profile-picture-preview"
                                                />
                                            ) : (
                                                <div className="profile-picture-placeholder">Bir fotoğraf seç</div>
                                            )}
                                        </div>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept="image/*"
                                            onChange={handleProfilePictureChange}
                                            className="my-form-control"
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>E-posta*</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="item02"
                                            value={regEmail}
                                            onChange={(e) => setRegEmail(e.target.value)}
                                            placeholder="E-postanı Gir *"
                                            className="my-form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Şifre*</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="item03"
                                            value={regPassword}
                                            onChange={(e) => setRegPassword(e.target.value)}
                                            placeholder="Şifreni Gir *"
                                            className="my-form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Şifreni Doğrula*</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="item04"
                                            value={regConPassword}
                                            onChange={(e) => setRegConPassword(e.target.value)}
                                            placeholder="Şifreni Gir *"
                                            className="my-form-control"
                                        />
                                    </div>
                                    <h4 className="content-title mt-5">Profil Detayları</h4>
                                    <div className="form-group">
                                        <label>İsim*</label>
                                        <input type="text" className="my-form-control"
                                            value={regName}
                                            onChange={(e) => setRegName(e.target.value)}
                                            placeholder="Tam ismini gir" />
                                    </div>
                                    <div className="form-group">
                                        <label>Doğum Tarihi*</label>
                                        <input type="date" className="my-form-control" onChange={handleBirthdayChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Ben bir*</label>
                                        <div className="banner__inputlist">
                                            <div className="s-input me-3">
                                                <input type="radio" name="gender1" id="males1" />
                                                <label htmlFor="males1">Erkeğim</label>
                                            </div>
                                            <div className="s-input">
                                                <input type="radio" name="gender1" id="females1" />
                                                <label htmlFor="females1">Kadınım</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Konum*</label>
                                        <input
                                            type="text"
                                            className="my-form-control"
                                            placeholder="Konumunu Eklemek İçin Tıkla"
                                            onClick={handleGetLocation}
                                            value={location.latitude && location.longitude ? "Konum Seçildi" : ""}
                                            readOnly
                                        />
                                        {locationErrorMessage && <p className="error-message">{locationErrorMessage}</p>}
                                    </div>
                                    <button onClick={handleSubmit} className="default-btn reverse"><span>Hesabını Oluştur</span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
}

export default SignUp;
