import { collection, doc, getDoc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { firestore, storage } from "../main_firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FeedModel } from "../../lib/models/feed";

export async function getCities() {
    const snapshot = await getDoc(doc(firestore, "feeds_cities/feeds_cities"));

    const cities = snapshot.data()["cities"] || []

    return cities;
}

export async function submitPost(user, selectedPhotos, caption, city, success, premiumCallback) {

    try {
        if (((!selectedPhotos || selectedPhotos.length === 0) && !caption) || !user) return;

        if (!city) {
            throw ("Bir şehir seçiniz!");
        }

        if (!user.userPurchased && (user.gender.toLowerCase() === "male" ||
            user.gender.toLowerCase() === "erkek")) {
            premiumCallback();
            return;
        }

        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);
        const millisecondsSinceEpoch = oneDayAgo.getTime();

        await getDocs(query(
            collection(firestore, "feeds"),
            where("userId", "==", user.userId),
            where("createdAt", ">=", millisecondsSinceEpoch)))
            .then(async (val) => {
                if (val.docs.length > 0) {
                    throw ("Günde bir kez paylaşım yapabilirsiniz");
                }

                const currentTime = new Date();

                const feedId =
                    user.userId + currentTime.getTime().toString();

                const urls = [];

                for (var file in selectedPhotos) {
                    const currentTime = new Date();
                    const storageRef = ref(storage, `feeds/${user.userId}/${currentTime.getTime().toString() + user.userId + file.name}`);

                    await uploadBytes(storageRef, file);
                    const url = await getDownloadURL(storageRef)
                    urls.push(url)
                }

                const feedModel = new FeedModel({
                    id: feedId,
                    caption:
                        caption.isEmpty ? null : caption,
                    userId: user.userId,
                    createdAt: Timestamp.fromDate(currentTime),
                    images: urls,
                    gender: user.gender,
                    city: city,
                    status: "waiting",
                    likes: [],
                });

                await setDoc(doc(firestore, `feeds/${feedModel.id}`), feedModel.toMap()).then((val) => {
                    alert("Post paylaşıldı, admin onayını bekleyin")
                    success()
                })
            })

    } catch (error) {
        alert(error)
        console.log(error)
    }


}