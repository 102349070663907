import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from 'firebase/firestore';
import { UserProfileModel } from "../../lib/models/user";
import { AppConfig } from "../../lib/common/appconfig";
import { auth, storage } from "../main_firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { UserAccountSettingsModel, UserLocation } from "../../lib/models/user_details";
import { firestore } from "../main_firebase";

export async function signUp(imageFile, email, password, passwordAgain, name, birthday, gender, location) {
    try {
        // Şifrelerin eşleşip eşleşmediğini kontrol et
        if (password !== passwordAgain) {
            throw new Error("Şifreler eşleşmiyor!");
        }

        // Eksik bilgi kontrolü
        if (!imageFile) {
            throw new Error("Profil resmi eklenmemiş!");
        }
        if (!email) {
            throw new Error("E-posta adresi girilmemiş!");
        }
        if (!password) {
            throw new Error("Şifre girilmemiş!");
        }
        if (!passwordAgain) {
            throw new Error("Şifre (tekrar) girilmemiş!");
        }
        if (!name) {
            throw new Error("Adınız girilmemiş!");
        }
        if (!birthday) {
            throw new Error("Doğum tarihi girilmemiş!");
        }
        if (!gender) {
            throw new Error("Cinsiyet seçilmemiş!");
        }
        if (!location) {
            throw new Error("Konum bilgisi girilmemiş!");
        }

        // Firebase kimlik doğrulama ile kayıt ol
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const user = result.user;
        const userId = user.uid;

        // Profil resmi yükle
        const profilePictureRef = ref(storage, `user_profile_pictures/${userId}.png`);
        await uploadBytes(profilePictureRef, imageFile);
        const ppDownloadUrl = await getDownloadURL(profilePictureRef);

        // Kullanıcı verilerini Firestore'a kaydet
        localStorage.setItem("user-id", user.uid);
        const userAccountSettingsModel = new UserAccountSettingsModel({
            location: new UserLocation(location.latitude, location.longitude),
            maximumAge: AppConfig.maximumUserAge,
            minimumAge: AppConfig.minimumAgeRequired,
        });

        const userProfileModel = new UserProfileModel({
            id: userId,
            userId: userId,
            fullName: name,
            mediaFiles: [],
            interests: [],
            gender: gender,
            purchaseStatus: false,
            bot: false,
            isForeign: false,
            profilePicture: ppDownloadUrl,
            birthDay: birthday,
            email: email,
            phoneNumber: "",
            userAccountSettingsModel: userAccountSettingsModel,
            isVerified: false,
        });

        await setDoc(doc(firestore, `userProfile/${user.uid}`), userProfileModel.toMap());

        // Kullanıcı giriş bilgilerini kaydet
        await setDoc(doc(firestore, `userLogin/${user.uid}`), {
            "loggedIn": true,
            "id": user.uid,
            "time": Date.now(),
        });

        localStorage.setItem("user-id", userId);

        return true;
    } catch (error) {
        console.error(error.code, error.message);
        alert(error.message);
        return false;
    }
}
