import { Fragment, useEffect, useState } from "react";
import Header from "../component/layout/header";
import Footer from "../component/layout/footer";
import { getUser } from "../scripts/auth/user_data";

const PurchaseComplete = () => {

    const [user, setUser] = useState(null);

    async function getUserData(uid) {
        const userData = await getUser(uid);
        setUser(userData);
    }

    useEffect(() => {
        getUserData(localStorage.getItem("user-id"));
    }, []);

    return (
        <Fragment>
            <Header />
            <h3 style={{display: "flex", justifyContent: "center", margin: 20}}>Ödeme Tamamlandı</h3>
            <Footer />
        </Fragment>
    );
};

export default PurchaseComplete;
