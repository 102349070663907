import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../scripts/auth/login";
import Footer from "../component/layout/footer";
import Logo from "../component/layout/logo";

const title = "Hoşgeldin";

const LogIn = () => {
    const [userEmail, setUserEmail] = useState('');
    const [userPass, setUserPass] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const ok = await login(userEmail, userPass);
        if (ok) {
            navigate("../");
        }
    };

    return (
        <section className="log-reg">
            <div className="top-menu-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-7">
                            <div className="logo">
                                <Logo />
                            </div>
                        </div>
                        <div className="col-lg-4 col-5">
                            <Link to="/" className="backto-home"><i className="fas fa-chevron-left"></i> Ana Sayfaya Dön</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="image image-log"></div>
                    <div className="col-lg-7">
                        <div className="log-reg-inner">
                            <div className="section-header inloginp">
                                <h2 className="title">{title}</h2>
                            </div>
                            <div className="main-content inloginp">
                                <form>
                                    <div className="form-group">
                                        <label>E-posta</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="item01"
                                            value={userEmail}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                            placeholder="E-postanı Gir *"
                                            className="my-form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Şifre</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="item02"
                                            value={userPass}
                                            onChange={(e) => setUserPass(e.target.value)}
                                            placeholder="Şifreni Gir *"
                                            className="my-form-control"
                                        />
                                    </div>
                                    {/*<p className="f-pass">Şifreni mi unuttun? <a href="#">şifreni kurtar</a></p>*/}
                                    <div className="text-center">
                                        <button onClick={handleSubmit} type="submit" className="default-btn"><span>Giriş YAP</span></button>
                                    </div>
                                    <div className="or-content">
                                        <br/>
                                        <p className="or-signup"> Hesabın mı yok? <Link to="/register">Buradan üye ol</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
}

export default LogIn;
