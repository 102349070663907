import { Component, Fragment, useEffect, useState } from "react";
import "../assets/css/extra/hometwo.css";
import Header from "../component/layout/header";
import { Link } from "react-router-dom";
import { getUsersNearby } from "../scripts/home/hometwo";
import { calcCrow, calculateAge } from "../scripts/utils/utils";
import { getUser } from "../scripts/auth/user_data";
import Footer from "../component/layout/footer";

const HomePageTwo = () => {

	const [users, setUsers] = useState(null)
	const [user, setUser] = useState(null)
	var gotUser = false

	const getUserData = async () => {
		gotUser = true
		const userData = await getUser(localStorage.getItem("user-id"))
		setUser(userData)
		if (userData) {
			const data = await getUsersNearby(userData, userData.userAccountSettingsModel.location.latitude, userData.userAccountSettingsModel.location.longitude)
			setUsers(data.filter((val) => val))
		}
	}

	useEffect(() => {
		if (!gotUser) {
			getUserData()
		}
	}, [])

	return (
		<Fragment>
			<Header />
			<p className="title_hometwo">Yakındakiler</p>
			{
				(users) ? <div className="group__bottom--body">
					<div className="member">
						<div className="users row g-3 row-cols-lg-4 row-cols-sm-2 row-cols-1">
							{users.map((val, i) => (
								<div className="col" key={i}>
									<div className="member__item w-100">
										<div className="member__inner m-0">
											<div className="member__thumb">
												<img className="thumb" src={`${val.profilePicture}`} alt={`Dating Thumb`} />
												<span className="member__activity">{(val.isOnline) ? "Çevrimiçi" : "Çevrimdışı"}</span>
											</div>
											<div className="member__content">
												<Link to={`/profile/${val.userId}`}><h6>{val.fullName}</h6></Link>
												{(val.birthDay) && <p>{calculateAge(val.birthDay).toString()} Yaşında</p>}
												{(user) && <p><i className="fa-solid fa-location-dot location_icon"></i>{calcCrow(user.userAccountSettingsModel.location.latitude, user.userAccountSettingsModel.location.longitude, val.userAccountSettingsModel.location.latitude, val.userAccountSettingsModel.location.longitude).toFixed()} km uzaklıkta</p>}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div> : <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400 }}>
					<span className="loader" style={{ width: 48, height: 48 }}></span>
				</div>
			}

			<Footer />

		</Fragment>
	);
}

export default HomePageTwo;