import { Fragment, useEffect, useState } from "react";
import HeaderOne from "../component/layout/header";
import { getAnyUser, getUser } from "../scripts/auth/user_data";
import { useParams } from 'react-router';
import '../assets/css/extra/profile_template/demo.css';
import '../assets/css/extra/profile_template/style.css';
import { calculateDistance, getMatchId } from "../scripts/home/hometwo";

const Profile = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [matchId, setMatchId] = useState(null);

    const loadUserData = async (idVal) => {
        const currentUserData = await getUser(localStorage.getItem("user-id"));
        setCurrentUser(currentUserData);
        const userData = await getAnyUser(idVal);
        setUser(userData);
        const matchIdData = await getMatchId(localStorage.getItem("user-id"), idVal);
        setMatchId(matchIdData);
    };

    useEffect(() => {
        loadUserData(id);
    }, [id]);

    const canSeeCertainStuff = () => {
        return currentUser && ((currentUser.gender === "female" || currentUser.gender === "kadın") ||
            (currentUser.gender === "male" || currentUser.gender === "erkek") &&
            currentUser.purchaseStatus);
    };

    return (
        <Fragment>
            <HeaderOne assetPrefix="../" />
            {user ? (
                <main className="main-view">
                    <div className="profile-card">
                        <div className="profile-card__header">
                            <img
                                className="content__avatar"
                                src={user.profilePicture || "https://www.pngrepo.com/png/384674/180/account-avatar-profile-user-11.png"}
                                alt="Profil"
                            />
                            <h2>{user.fullName}</h2>
                            <span className="age-badge">
                                {new Date().getFullYear() - new Date(user.birthDay).getFullYear()} Yaşında
                            </span>
                            <p style={{ marginTop: 10 }}>
                                {calculateDistance(
                                    currentUser?.userAccountSettingsModel.location.latitude,
                                    currentUser?.userAccountSettingsModel.location.longitude,
                                    user.userAccountSettingsModel.location.latitude,
                                    user.userAccountSettingsModel.location.longitude
                                ).toFixed()} km uzakta
                            </p>
                        </div>
                        <div className="profile-card__body">
                            {(user.about) && <div className="profile-card__detail">
                                <h3>Hakkında</h3>
                                <p>{user.about}</p>
                            </div>}
                            <div className="profile-card__detail">
                                <h3>WhatsApp</h3>
                                {canSeeCertainStuff() ? (
                                    <p>{user.whatsapp || "Whatsapp Eklemedi"}</p>
                                ) : (
                                    <button
                                        className="premium-btn"
                                        onClick={() => window.location.href = "../../premium"}
                                    >
                                        Premiuma Yükselt
                                    </button>
                                )}
                            </div>
                            <div className="profile-card__detail">
                                <h3>Instagram</h3>
                                {canSeeCertainStuff() ? (
                                    <p>{user.instagram || "Instagram Eklemedi"}</p>
                                ) : (
                                    <button
                                        className="premium-btn"
                                        onClick={() => window.location.href = "../../premium"}
                                    >
                                        Premiuma Yükselt
                                    </button>
                                )}
                            </div>
                            <div className="profile-card__detail">
                                <h3>İlgi Alanları</h3>
                                <div className="interests">
                                    {user.interests.length > 0 ? user.interests.map((interest, index) => (
                                        <span key={index} className="interest-item">{interest}</span>
                                    )) : <span className="interest-item">None</span>}
                                </div>
                            </div>
                        </div>
                        <button className="message-btn" onClick={() => window.location.href = `../../chats/${user.userId}/${(matchId) ? matchId : currentUser ? (currentUser.userId + user.userId) : ""}`}>
                            <i className="fa fa-comments"></i>
                        </button>
                    </div>
                </main>
            ) : (
                <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 600 }}>
                    <span className="loader" style={{ width: 48, height: 48 }}></span>
                </div>
            )}
        </Fragment>
    );
};

export default Profile;
