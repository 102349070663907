import { Fragment, useEffect, useState } from "react";
import "../assets/css/extra/hometwo.css";
import Header from "../component/layout/header";
import '../assets/css/extra/chatpage.css';
import { getAnyUser, getUser } from "../scripts/auth/user_data";
import { getChat } from "../scripts/chats/chats";
import { useParams } from "react-router-dom";
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore";
import { firestore } from "../scripts/main_firebase";
import { generateRandomId } from "../scripts/utils/utils";
import { reportUser, uploadFileToStorage } from "../scripts/chats/chatpage";
import Footer from "../component/layout/footer";

const ChatPage = () => {
    const { id, matchId } = useParams();
    const [user, setUser] = useState(null);
    const [chatUser, setChatUser] = useState(null);
    const [chat, setChat] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [reportDetails, setReportDetails] = useState("");
    const [reportImage, setReportImage] = useState(null);
    const [sendingReport, setSendingReport] = useState(false)

    const getUserData = async () => {
        const userData = await getUser(localStorage.getItem("user-id"));
        const chatUserData = await getAnyUser(id);

        if (chatUserData && userData) {
            setChatUser(chatUserData);
            setUser(userData);
        } else {
            setNotFound(true);
            return;
        }

        if (userData.userId) {
            await getChat((val) => setChat(val), matchId);
        }
    };

    // Handle file/image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const handleReportImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setReportImage(file);
        }
    };

    const handleSendMessage = async () => {
        if (message || selectedImage) {
            if ((user.gender === "male" || user.gender === "erkek") && !user.purchaseStatus) {
                window.location.href = "../../premium";
                return
            }

            if ((user.gender === "female" || user.gender === "kadın") && (chat.gender === "female" || chat.gender === "kadın")) {
                alert("Hemcinsine mesaj atamazsın");
                return;
            }

            const currentTime = new Date().getTime();
            let imageUrl;

            if (selectedImage) {
                imageUrl = await uploadFileToStorage(selectedImage, "images");
            }

            const id_data = generateRandomId(13);

            const chatItem = {
                id: id_data,
                message: message || null,
                createdAt: currentTime,
                userId: user.userId,
                matchId,
                isRead: false,
                image: imageUrl || null,
            };

            await setDoc(doc(firestore, `matches/${matchId}`), {
                id: matchId,
                last_message: message || (imageUrl ? "Fotoğraf" : ""),
                last_time: Timestamp.now(),
                messaging: false,
                userIds: [id, user.userId],
            });

            await setDoc(doc(collection(firestore, `matches/${matchId}/chat/`), id_data), chatItem);

            // Reset message and media inputs
            setMessage('');
            setSelectedImage(null);
        }
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            await deleteDoc(doc(firestore, `matches/${matchId}/chat/${messageId}`));
            // Update the chat state by filtering out the deleted message
            setChat((prevChat) => prevChat.filter((message) => message.id !== messageId));
            alert("Mesaj silindi");
        } catch (error) {
            console.error("Mesaj silme hatası:", error);
            alert("Mesaj silinirken bir hata oluştu.");
        }
    };    

    // Handle report dialog open
    const handleReport = () => {
        setReportDialogOpen(true);
    };

    // Handle report submission
    const handleSubmitReport = async () => {
        setSendingReport(true)
        const result = await reportUser(chatUser.userId, reportDetails, reportImage, user.userId);
        if (result) {
            setReportDialogOpen(false);
            setReportDetails("");
            setReportImage(null);
            alert("Kullanıcı şikayet edildi!")
        }
        setSendingReport(false)
    };

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <Fragment>
            <Header assetPrefix="../../" />
            {notFound ? (
                <div className="not-found">Sohbet Bulunamadı</div>
            ) : user && chatUser ? (
                <div className="chat-page">
                    {/* Chat User at the top */}
                    <div className="chat-header">
                        <a href={`../../../profile/${chatUser.userId}`} style={{ display: "flex", flexDirection: "row" }}>
                            <img src={chatUser.profilePicture} alt={chatUser.fullName} className="chat-user-avatar" />
                            <h3>{chatUser.fullName}</h3>
                        </a>
                        <div style={{ display: "flex", flexGrow: 1 }}></div>
                        <button onClick={handleReport} className="more-button">Şikayet Et</button>
                    </div>

                    {/* Chat Messages */}
                    <div className="chat-body">
                        {chat.length === 0 && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>Mesaj Yok</div>}
                        {chat.map((messageItem, index) => (
                            <div key={index} className={`${messageItem.userId === user.userId ? 'sent-0' : 'received-0'}`}>
                                <div className={`chat-message ${messageItem.userId === user.userId ? 'sent' : 'received'}`}>
                                    {messageItem.userId === user.userId && (
                                        <button
                                            onClick={() => handleDeleteMessage(messageItem.id)}
                                            className="delete-button"
                                            aria-label="Delete Message"
                                        >
                                            <i className="fa-solid fa-trash"></i>
                                        </button>
                                    )}
                                    {messageItem.image && <img src={messageItem.image} alt="sent media" className="chat-image" />}
                                    {messageItem.message && <p className="chat-text">{messageItem.message}</p>}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Send Message Area */}
                    <div className="chat-footer">
                        <label htmlFor="image-upload" className="image-select fa-solid fa-image"></label>
                        <input
                            id="image-upload"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                        />

                        {/* Show image preview if selected */}
                        {selectedImage && (
                            <div className="image-preview">
                                <img src={URL.createObjectURL(selectedImage)} alt="Preview" />
                                <button onClick={() => setSelectedImage(null)} className="remove-image">X</button>
                            </div>
                        )}

                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Bir mesaj yaz..."
                            className="chat-input"
                        />
                        <button onClick={handleSendMessage} className="send-button">Gönder</button>
                    </div>

                    {reportDialogOpen && (
                        <div className="report-dialog">
                            <h4>Şikayet Et</h4>
                            <p>Kullanıcı: {chatUser.fullName}</p>
                            <img src={chatUser.profilePicture} alt={chatUser.fullName} className="chat-user-avatar" />
                            <textarea
                                value={reportDetails}
                                onChange={(e) => setReportDetails(e.target.value)}
                                placeholder="Şikayet detaylarını yazınız..."
                                className="report-details"
                            ></textarea>

                            <label htmlFor="report-image-upload" className="image-select bottom-m-30">
                                Fotoğraf Yükle
                            </label>
                            <input
                                id="report-image-upload"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleReportImageChange}
                            />

                            {reportImage && (
                                <div className="center">
                                    <div style={{ width: 80 }} className="image-preview">
                                        <img src={URL.createObjectURL(reportImage)} alt="Preview" />
                                        <button onClick={() => setReportImage(null)} className="remove-image">X</button>
                                    </div>
                                </div>
                            )}

                            {/* Show loading indicator or disable button while sending */}
                            <button onClick={handleSubmitReport} className="send-button" disabled={sendingReport}>
                                {sendingReport ? "Gönderiliyor..." : "Gönder"}
                            </button>
                            <button onClick={() => setReportDialogOpen(false)} className="cancel-button" disabled={sendingReport}>
                                İptal
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div id="loading" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400 }}>
                    <span className="loader" style={{ width: 48, height: 48 }}></span>
                </div>
            )}
            <Footer />
        </Fragment>
    );
};

export default ChatPage;
